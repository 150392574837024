<template>
  <el-row class="dashboard-container" id="dashboard-index-container">
    <el-row :gutter="10">
      <el-col :lg="16" :md="12" class="quota-container">
        <el-row :gutter="10">
          <el-col :lg="12" :md="20" style="margin-bottom: 10px;">
            <div class="total-container">
              <div class="total-title">平台统计</div>
              <div class="total-content">
                <div>
                  <div class="total-name">农户总数(户)</div>
                  <div class="total-value">{{pageData && pageData.farmerSize || 0}}</div>
                </div>
                <div>
                  <div class="total-name">已签约(份)</div>
                  <div class="total-value">{{pageData && pageData.alreadySignatureSize || 0}}</div>
                </div>
                <div>
                  <div class="total-name">项目公司</div>
                  <div class="total-value">{{pageData && pageData.developersSize || 0}}</div>
                </div>
                <div>
                  <div class="total-name">经销商总数</div>
                  <div class="total-value">{{pageData && pageData.distributorSize || 0}}</div>
                </div>
                <div>
                  <div class="total-name">平台用户</div>
                  <div class="total-value">{{pageData && pageData.userSize || 0}}</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :lg="12" :md="20" class="quota-container" style="margin-bottom: 10px;">
            <div class="total-container bg-line">
              <div class="total-title el-row--flex">
                <div>每日统计</div>
                <div style="flex: 1;text-align: right;color: #fff;font-weight: unset;font-size: 16px">{{ today }}</div>
              </div>
              <div class="total-content">
                <div>
                  <div class="total-name">新增农户</div>
                  <div class="total-value">{{pageData && pageData.todayFarmerSize || 0}}</div>
                </div>
                <div>
                  <div class="total-name">新增签约（份）</div>
                  <div class="total-value">{{pageData && pageData.todayAlreadySignatureSize || 0}}</div>
                </div>
                <div>
                  <div class="total-name">新增并网</div>
                  <div class="total-value">{{pageData && pageData.todayConnectionSize || 0}}</div>
                </div>
                <div>
                  <div class="total-name">新增验收</div>
                  <div class="total-value">{{pageData && pageData.todayOverSize || 0}}</div>
                </div>
                <div>
                  <div class="total-name">划转农户新增</div>
                  <div class="total-value">{{pageData && pageData.todayPowerStationSize || 0}}</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="step-container">
            <div class="step-title">
              <div>业务统计</div>
              <el-button type="primary" size="small" @click="exportAllAction">全部导出</el-button>
            </div>
            <div class="stepFlex">
              <div v-for="(item, index) in lineData" :key="index" @click="statusClick(item,index)"
                   style="position: relative; flex: 1; text-align: center;margin-top:-10px">
                <div class="step-name">{{ item.name }}</div>
                <div class="greyCircle">
                  <div></div>
                </div>
                <div v-if="index !== lineData.length - 1" class="greyline"></div>
                <div class="step-value">{{ item.num }}户</div>
              </div>
            </div>
          </div>
          <!--左下列表用户管理-->
          <div class="data-table">
            <page-table ref="farmerTable" show-paginate :get-data="farmerPage" :fields="farmerFields"
                        :search-model="searchModel"
                        :show-search="false" :show-selection="true" :item-actions="farmerActions"
                        @onActionClick="handleItemActionClick">
              <template slot="page-actions">
                <el-button @click="exportFarmerBatch" size="mini" type="primary">批量导出</el-button>
              </template>
            </page-table>
          </div>
        </el-row>
      </el-col>
      <el-col :lg="8" :md="12" class="quota-container">
        <div class="task-container">
          <div class="task-title">
            <div class="title">工单信息</div>
            <div class="more" @click="viewMore('/admin/service-order')">查看更多</div>
          </div>
          <div>
            <el-table :data="topTaskList" style="width: 100%;color: #000 !important;" class="table-component">
              <el-table-column prop="id" label="工单编号" align="center" width="180"></el-table-column>
              <el-table-column label="经销商名称" min-width="120" align="center">
                <template slot-scope="scope">
                  {{scope.row.user && scope.row.user.distributor && scope.row.user.distributor.name || '-'}}
                </template>
              </el-table-column>
              <el-table-column label="踏勘人员" align="center" width="120">
                <template slot-scope="scope">
                  {{scope.row.user && scope.row.user.name || '-'}}
                </template>
              </el-table-column>
              <el-table-column label="手机号" align="center" width="120">
                <template slot-scope="scope">
                  {{scope.row.user && scope.row.user.phone || '-'}}
                </template>
              </el-table-column>
              <el-table-column label="农户姓名" align="center" width="110">
                <template slot-scope="scope">
                  {{scope.row.farmer && scope.row.farmer.name || '-'}}
                </template>
              </el-table-column>
              <el-table-column prop="time" label="提交时间" width="180s"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="task-container">
          <div class="task-title">
            <div class="title">建站待审核</div>
            <div class="more" @click="viewMore('/admin/service-station')">查看更多</div>
          </div>
          <div>
            <el-table :data="midTaskList" style="width: 100%;color: #000 !important;" class="table-component">
              <el-table-column prop="id" label="工单编号" align="center" width="180"></el-table-column>
              <el-table-column label="农户姓名" align="center" width="110">
                <template slot-scope="scope">
                  {{scope.row.farmer && scope.row.farmer.name || '-'}}
                </template>
              </el-table-column>
              <el-table-column label="手机号" align="center" width="120">
                <template slot-scope="scope">
                  {{scope.row.user && scope.row.farmer.phone || '-'}}
                </template>
              </el-table-column>
              <el-table-column label="农户地址" width="150" align="center">
                <template slot-scope="scope">
                  {{scope.row.farmer && scope.row.farmer.province || ''}}{{scope.row.farmer && scope.row.farmer.city ||
                  ''}}{{scope.row.farmer && scope.row.farmer.area || ''}}{{scope.row.farmer && scope.row.farmer.address
                  || ''}}
                </template>
              </el-table-column>
              <el-table-column prop="distance" label="并网距离" align="center" width="110"></el-table-column>
              <el-table-column label="预估组件" width="100">
                <template slot-scope="scope">
                  {{scope.row.estimateSize}}块
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="task-container">
          <div class="task-title">
            <div class="title">划转待审核</div>
            <div class="more" @click="viewMore('/admin/de-user-examine')">查看更多</div>
          </div>
          <div>
            <el-table :data="bottomList" style="width: 100%;color: #000 !important;" class="table-component">
              <el-table-column prop="wordOrderId" label="工单编号" align="center" width="180"></el-table-column>
              <el-table-column prop="name" label="农户姓名" align="center" width="110"></el-table-column>
              <el-table-column prop="phone" label="手机号" align="center" width="120"></el-table-column>
              <el-table-column prop="idNo" label="身份证" align="center" width="180"></el-table-column>
              <el-table-column prop="item_price" label="租金单价" width="120">
                <template slot-scope="scope">{{scope.row.item_price || '-'}}</template>
              </el-table-column>
              <el-table-column label="理论划转总金额" width="120">
                <template slot-scope="scope">{{scope.row.hzMoney || '-'}}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog v-if="curFarmer" :title="curFarmer.type == 1?'农户详情': '公共屋顶详情'"
               :visible.sync="farmer_dialog_visible"
               append-to-body :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <div style="display: flex;flex-wrap: wrap;">
        <div style="width: 50%;line-height: 40px;">
          <span style="padding-right: 10px">{{curFarmer.type == 1 ? '业主姓名': '姓名'}}：</span>
          <span>{{curFarmer.name}}</span>
        </div>
        <div style="width: 50%;line-height: 40px;">
          <span style="padding-right: 10px">用户类型：</span>
          <span>{{curFarmer.type == 1? '公共屋顶': '农户'}}</span>
        </div>
        <div v-if="curFarmer.type == 1" style="width: 50%;line-height: 40px;">
          <span style="padding-right: 10px">统一信用代码：</span>
          <span>{{curFarmer.unifiedCreditCode}}</span>
        </div>
        <div v-if="curFarmer.type == 1" style="width: 50%;line-height: 40px;">
          <span style="padding-right: 10px">联系人：</span>
          <span>{{curFarmer.contacts}}</span>
        </div>
        <div v-if="curFarmer.type == 0" style="width: 50%;line-height: 40px;">
          <span style="padding-right: 10px">身份证号：</span>
          <span>{{curFarmer.idNo}}</span>
        </div>
        <div style="width: 50%;line-height: 40px;">
          <span style="padding-right: 10px">电话：</span>
          <span>{{curFarmer.phone}}</span>
        </div>
        <div style="width: 50%;line-height: 40px;">
          <span style="padding-right: 10px">{{curFarmer.type == 1?'屋顶地址': '地址'}}：</span>
          <span>{{curFarmer.province}} {{curFarmer.city}} {{curFarmer.area}}</span>
        </div>
        <div style="width: 100%;line-height: 40px;">
          <span style="padding-right: 10px">详细地址：</span>
          <span>{{curFarmer.address}}</span>
        </div>
        <div style="width: 100%;line-height: 40px;">
          <span style="padding-right: 10px">备注：</span>
          <span>{{curFarmer.remarks}}</span>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="farmer_dialog_visible = false">关闭</el-button>
      </div>
    </el-dialog>

  </el-row>
</template>

<script>
  import dayjs from 'dayjs'
  import PageTable from "@/components/PageTable"
  // import erd from 'element-resize-detector'
  import {
    dashBoardData,
    dashBoardExportAll,
    dashBoardExport,
    farmerPage,
    dashBoardTaskList,
    dashBoardHuazhaunList
  } from '@/api/common'
  import {taskStateName} from '@/utils/helper'
  import downloadBlob from "@/function/downloadBlob"

  const today = dayjs().format('YYYY-MM-DD')

  export default {
    name: "Index",
    components: {
      PageTable
    },
    data() {
      return {
        today: today,
        loading: false,
        pageData: null,
        lineData: [
          {name: '待踏勘', num: 0, status: 'SURVEYED'},
          {name: '建站审核', num: 0, status: 'EXAMINE'},
          {name: '待签约', num: 0, status: 'WAITING_SIGN'},
          {name: '施工中', num: 0, status: 'WORKING'},
          {name: '提交并网', num: 0, status: 'UPLOAD'},
          {name: '验收审核', num: 0, status: 'WAIT_RECEIVE'},
          // {name: '验收通过', num: 0, status: 'OVER'},
          {name: '划转复审', num: 0, status: 'HZ_EXAMINE'},
          {name: '划转收益中', num: 0, status: 'HZ_PROFIT'},
        ],
        curIndex: 0,
        searchModel: {
          webSearchState: 'SURVEYED', // 前面7个
          webSearchPowerState: "", // 后面2个
        },
        farmerPage: farmerPage,
        farmerFields: [
          {label: '工单编号', key: 'wordOrderId', width: 180},
          {
            label: "工单状态",
            width: 80,
            render({webSearchState}, h) {
              return h('span', taskStateName(webSearchState))
            }
          },
          {label: '农户姓名', key: 'name', width: 110},
          {label: '农户电话', key: 'phone', width: 120},
          {
            label: '农户地址', width: 160,
            render({province, city, area, address}, h) {
              return h('span', `${province}${city}${area}${address}`)
            }
          },
          {
            label: '经销商名称', width: 140,
            render({createUser}, h) {
              if (createUser && createUser.distributor) {
                return h('span', createUser.distributor.name || '-')
              } else {
                return h('span', '')
              }
            }
          },
          {
            label: '踏勘人员', width: 120,
            render({user}, h) {
              return h('span', user && user.name || '-')
            }
          },
          {
            label: '手机号', width: 120,
            render({user}, h) {
              return h('span', user && user.phone || '-')
            }
          },
          {label: '提交时间', key: 'time', width: 180,},
          {label: '停留时长', key: 'lastTimeStr', width: 120,},
        ],
        farmerActions: [
          {action: 'farmerInfo', label: '查看详情', type: 'primary'},
        ],
        curFarmer: null,
        farmer_dialog_visible: false,
        topTaskList: [],
        midTaskList: [],
        bottomList: [],
      }
    },
    created() {
      this.getData()
      this.getTaskData('')
      this.getTaskData('EXAMINE')
      this.getHuazhuanData()
    },
    mounted() {

    },
    methods: {
      getData() {
        let that = this
        dashBoardData().then(res => {
          that.pageData = res
          that.lineData = [
            {name: '待踏勘', num: res.surveyed, status: 'SURVEYED'},
            {name: '建站审核', num: res.examine, status: 'EXAMINE'},
            {name: '待签约', num: res.waitingSign, status: 'WAITING_SIGN'},
            {name: '施工中', num: res.working, status: 'WORKING'},
            {name: '提交并网', num: res.upload, status: 'UPLOAD'},
            {name: '验收审核', num: res.waitReceive, status: 'WAIT_RECEIVE'},
            // {name: '验收通过', num: res.over, status: 'OVER'},
            {name: '划转复审', num: res.powerStationExamineSize, status: 'HZ_EXAMINE'},
            {name: '划转收益中', num: res.powerStationSize, status: 'HZ_PROFIT'},
          ]
        })
      },
      statusClick(item, index) {
        let that = this
        if(index != that.curIndex){
          that.curIndex = index
          if(index >= 6 ){
            this.searchModel.webSearchState = ''
            this.searchModel.webSearchPowerState = item.status
          } else {
            this.searchModel.webSearchState = item.status
            this.searchModel.webSearchPowerState = ''
          }
          this.$refs.farmerTable.loadData()
        }
      },
      getHuazhuanData() {
        let that = this
        dashBoardHuazhaunList().then(res => {
          for (let item of res.records) {
            item.item_price = item.configure.type == 0 ? (item.configure.rent / 100).toFixed(2) : (item.configure.rent1 / 100).toFixed(2) + ',' + (item.configure.rent2 / 100).toFixed(2)
          }
          that.bottomList = res.records
        })
      },
      getTaskData(val) {
        let that = this
        dashBoardTaskList(val).then(res => {
          if (val !== '') {
            that.midTaskList = res.records
          } else {
            that.topTaskList = res.records
          }
        })
      },
      exportAllAction() {
        let loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        dashBoardExportAll().then(res => {
          downloadBlob(res)
          loading.close()
        }).catch(() => {
          loading.close()
        })
      },
      handleItemActionClick(action, item) {
        let that = this
        that[action + 'Action'](item)
      },
      farmerInfoAction(item) {
        this.curFarmer = item
        this.farmer_dialog_visible = true
      },
      // 批量导出
      exportFarmerBatch() {
        let that = this, ids = []
        let selected = that.$refs.farmerTable.selection
        for (let item of selected) {
          ids.push(item.id)
        }
        let data = {
          exportIds: ids.length ? ids.join(',') : ''
        }
        data = Object.assign(data, that.searchModel)
        dashBoardExport(data).then(res => {
          downloadBlob(res)
        }).catch(err => {
          console.log(err)
        })
      },
      viewMore(path) {
        this.$router.push({path: path})
      },
    }
  }
</script>

<style lang="scss" scoped>
  .bg-line {
    background: linear-gradient(180deg, #1890FF 0%, #F2F9FF 40%, #FFFFFF 100%);
  }

  .quota-container {
    height: 100%;
    .total-container {
      padding: 20px;
      box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.16);
      .total-title {
        font-size: 16px;
        font-weight: bold;
        color: #000;
      }
      .total-content {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > div {
          text-align: center;
          box-sizing: border-box;
        }

        .total-name {
          color: #817C82;
          font-size: 14px;
        }
        .total-value {
          margin-top: 10px;
          color: #000;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
    .step-container {
      padding: 20px;
      box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.16);
      .step-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
      }
      .stepFlex {
        margin-top: 40px;
        display: flex;
        .step-name {
          color: #1890FF;
          font-size: 14px;
          font-weight: bold;
          padding-bottom: 22px;
        }
        .step-value {
          color: #000;
          font-size: 14px;
        }
        .greyCircle {
          top: calc(50% - 8px);
          left: calc(50% - 8px);
          position: absolute;
          z-index: 2;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #1890FF;
          & > div {
            margin-top: 3px;
            margin-left: 3px;
            width: 10px;
            height: 10px;
            background-color: #00D0FF;
            border-radius: 50%;
          }
        }

        .greyline {
          height: 0;
          border: 1px solid #ccc;
          width: 100%;
          top: calc(50% - 2px);
          left: calc(50% - 2px);
          position: absolute;
        }
      }
    }
    .data-table {
      box-shadow: 0 5px 5px 1px #ccc;
    }

    .task-container {
      min-height: 300px;
      margin-bottom: 20px;
      padding: 20px;
      box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.16);

      .task-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 16px;
          font-weight: bold;
        }
        .more {
          color: #1890FF;
          font-size: 14px;
        }
      }
    }
  }

  /deep/ .el-table th.el-table__cell > .cell {
    padding-left: 3px;
    padding-right: 3px;
    color: #000;
    font-weight: normal;
  }
</style>